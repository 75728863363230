import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"

class LocationMap extends React.Component {
  constructor() {
    super()
    this.state = { isCenturion: true }
  }

  render() {
    const locationMap = this.props.globalJson.locationMap
    const centurionLounges = this.props.allLocationsJson.nodes
    const intlLounges = this.props.internationalJson.airports

    return (
      <div id="locations" className="locations">
        <div id="locations-anchor" className="anchor"></div>
        <div className="container container--med islet">
          <h2 className="island text-center">{locationMap.title}</h2>
        </div>
        <div className="container">
          <div className="locations-map">
            <div className="hidden-below-lap">
              <Img fluid={locationMap.desktopMapImage.localFile.childImageSharp.fluid} alt="world map" />
            </div>
            <div className="current locations-tab">
              <div className="hidden-lap-and-up">
                <Img fluid={locationMap.mobileCenturionMapImage.localFile.childImageSharp.fluid} alt="world map of Centurion lounges" />
              </div>
              <div className="hidden-below-lap">
                {centurionLounges && centurionLounges.map( (lounge, index) => {
                  return (
                    <div key={index} className={lounge.airportCode + " dot-container"}>
                      <Link className="map-dot" to={"/locations/" + lounge.airportCode.toLowerCase() + "/"}>
                        <svg viewBox="4 1 24 24">
                          <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
                        </svg>
                      </Link>
                      <div className="map-name"><span>{lounge.shortAirportName}</span><br />{lounge.airportCode}</div>
                    </div>
                  )
                })}
                {intlLounges && intlLounges.map( (lounge, index) => {
                  return (
                    <div key={index} className={lounge.airportCode + " dot-container"}>
                      {lounge.showInListing &&
                        <>
                          <Link className="map-dot" to={"/international/#" + lounge.airportCode.toLowerCase()}>
                            <svg viewBox="4 1 24 24">
                              <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
                            </svg>
                          </Link>
                          <div className="map-name"><span>{lounge.shortAirportName}</span><br />{lounge.airportCode}</div>
                        </>
                      }
                    </div>
                  )
                })}
              </div>
            </div>
            
          </div>
        </div>

        <div className="inline-listings--wrapper hidden-lap-and-up">
          <ul className="current inline-listings">
            {centurionLounges && centurionLounges.map( (lounge, index) => {
              return (
                <li key={index}>
                  <Link key={index} to={"/locations/" + lounge.airportCode.toLowerCase() + "/"}>
                    {lounge.shortAirportName} ({lounge.airportCode})
                  </Link>
                </li>
              )
            })}
            {intlLounges && intlLounges.map( (lounge, index) => {
              return (
                <li key={index}>
                  {lounge.showInListing &&
                    <Link to={"/international/#" + lounge.airportCode.toLowerCase()}>
                      {lounge.shortAirportName} ({lounge.airportCode})
                    </Link>
                  }
                </li>
              )
            })}
          </ul>
        </div>

        <div className="container container--narrow island text-center">
          {locationMap.intro &&
            <p dangerouslySetInnerHTML={{__html: locationMap.intro}} />
          }
          {locationMap.buttonLabel &&
            <p className="islet"><a className="btn" href={locationMap.buttonUrl} target="_blank" rel="noopener noreferrer">{locationMap.buttonLabel}</a></p>
          }
          {locationMap.appText &&
            <div className="legal" dangerouslySetInnerHTML={{__html: locationMap.appText}} />
          }
        </div>
      </div>
    )
  }
}

LocationMap.propTypes = {
  globalJson: PropTypes.shape({
    locationMap: PropTypes.object.isRequired,
  }).isRequired,
  allLocationsJson: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  internationalJson: PropTypes.shape({
    airports: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strapiCenturionGlobal {
          locationMap {
            title
            desktopMapImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1139) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            mobileCenturionMapImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 676) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            mobileOtherMapImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 676) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            centurionMapLabel
            otherMapLabel
            intro
            buttonLabel
            buttonUrl
            appText
          }
        }
        allStrapiTheCenturionLounge(
          filter: {
            showInListing: {eq: true}},
            sort: {fields: [shortAirportName],
              order: ASC})
          {
          nodes {
            airportCode
            shortAirportName
            showInListing
          }
        }
        strapiCenturionInternational {
          airports {
            airportCode
            shortAirportName
            showInListing
          }
        }
      }
    `}
    render={({ strapiCenturionGlobal, allStrapiTheCenturionLounge, strapiCenturionInternational }) => <LocationMap globalJson={strapiCenturionGlobal} allLocationsJson={allStrapiTheCenturionLounge} internationalJson={strapiCenturionInternational} {...props} />}
  />
)
